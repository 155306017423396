import { useState, useEffect } from 'react';
import './App.css';
import 'antd/dist/antd.css';
import MainPage from './Components/MainPage';
import About from './Components/About';
import Contact from './Components/Contact';
import { Layout, Menu } from 'antd';
import { Redirect, Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
const { Header, Footer } = Layout;
const { Item } = Menu;

function App() {
  const [tab, setTab] = useState('2');
  useEffect(() => {
    const dic = {
      '/': '2',
      '/about': '1',
      '/contact': '3'
    }
    setTab(dic[window.location.pathname])
  }, []);
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <Router>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%', background: '#f4c430' }}>
          <Menu mode="horizontal" selectedKeys={[tab]}  style={{ background: '#f4c430', display: 'flex', justifyContent: 'space-between' }}>
              <Item key="1" style={{ width: '100px', textAlign: 'center' }} onClick={() => setTab('1')}><Link to="/about">About</Link></Item>
              <Item key="2" style={{ width: '100px', textAlign: 'center' }} onClick={() => setTab('2')}><Link to="/">Home</Link></Item>
              <Item key="3" style={{ width: '100px', textAlign: 'center' }} onClick={() => setTab('3')}><Link to ="/contact">Contact</Link></Item>
          </Menu>
        </Header>
          <Switch>
            <Route exact path="/" component={MainPage} />  
            <Route exact path="/about" component={About}/>
            <Route exact path="/contact" component={Contact}/>
            <Redirect to="/"/>
          </Switch>
        </Router>
        <Footer style={{ paddingTop: '0px', textAlign: 'center', background: '#abafae', maxHeight: '10px' }}>Copyright © {new Date().getFullYear()} Rajnish Kumar Pathela </Footer>
      </Layout>
    </div>
  );
}

export default App;
