import React, { Component } from "react";
import { Layout, Typography, Tooltip } from "antd";
import "../App.css";

// Destructure useful component
const { Content } = Layout;
const { Title, Text } = Typography;

// Import Images
var lic = require("../images/lic.svg");
var tnac = require("../images/theNewIndiaAssurance.png");
var hdfc = require("../images/hdfcErgo.png");

class MainPage extends Component {
  render() {
    return (
      <Content style={{ padding: "0 50px", marginTop: 64 }}>
        <div style={{ padding: 24, minHeight: 380, marginTop: 100 }}>
          <Title>Financial Advisor</Title>
          <Title level={3}>Rajnish Kumar Pathela</Title>
          <Title level={4}>with</Title>
          <Title level={4}>
            {new Date().getFullYear() - 1997}+ years of Experience | Chairman’s
            Club Member @LIC | Investment Planner | Clients from 20+ cities
          </Title>
          <Title level={4}>
            General Insurance | Life Insurance | Health Insurance
          </Title>
        </div>
        <Text strong> Associated with: </Text>
        <div
          style={{ marginTop: "30px", maxWidth: "800px", margin: "auto" }}
          className="flex"
        >
          <div>
            <Tooltip title="Agent at The New India Assurance Co. Ltd.">
              <img
                src={tnac.default}
                alt="The New India Assurance Co. Ltd."
                width="150px"
                height="150px"
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Chief Life Insurance Advisor and Chairman’s Club Member at LIC">
              <img src={lic.default} alt="lic" width="150px" height="150px" />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Agent at HDFC Ergo Health Insurance">
              <img
                src={hdfc.default}
                alt="HDFC Ergo Health Insurance"
                width="150px"
                height="150px"
              />
            </Tooltip>
          </div>
        </div>

        <div style={{ marginTop: "40px", background: "#ADD8E6" }}>
          <Title level={5}>
            Get first three Insurance consultation for free!
          </Title>
          For booking consultation session related to financial advise please
          email to <a href="mailto:pathela01@gmail.com">pathela01@gmail.com</a>{" "}
          mentioning your query in brief,
          <br />
          or connect on{" "}
          <a
            href="https://www.linkedin.com/in/rajnish-kumar-pathela/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn{" "}
          </a>
          <div>
            Also connect for Corporate Insurance, Employee Benefits, Group
            Health Insurance.
            <br />
            <small>
              Note: After free consultation if you buy an Insurance, any
              additional consultation session will be free for lifetime for our
              policyholders
            </small>
          </div>
        </div>
      </Content>
    );
  }
}

export default MainPage;
