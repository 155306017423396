import React, { Component } from "react";
import { Layout, Typography } from "antd";
const { Content } = Layout;
const { Title } = Typography;
class Contact extends Component {
  render() {
    return (
      <Content style={{ padding: "0 50px", marginTop: 64 }}>
        <div style={{ marginTop: "50px" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.8253814785357!2d82.97714787539344!3d25.31007022711962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2d5b66b21cbb%3A0xae86e2f0e0a8b11d!2sBima%20Seva%20Kendra!5e0!3m2!1sen!2sin!4v1682440592213!5m2!1sen!2sin"
            width="800"
            height="600"
            style={{ border: 0 }}
            allowfullscreen=""
            title="Bima Seva Kendra"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div style={{ marginTop: "100px" }}>
          <Title level={4}>
            Email ID:{" "}
            <a href="mailto:pathela01@gmail.com">pathela01@gmail.com</a>, &nbsp;
            <a href="mailto:pathela1@rediffmail.com">pathela1@rediffmail.com</a>
          </Title>
          <Title level={4}>
            LinkedIn:{" "}
            <a
              href="https://www.linkedin.com/in/rajnish-kumar-pathela/"
              target="_blank"
              rel="noreferrer"
            >
              Rajnish Kumar Pathela
            </a>
          </Title>
          <Title level={4}>
            Facebook:{" "}
            <a
              href="https://www.facebook.com/rajnish.pathela"
              target="_blank"
              rel="noreferrer"
            >
              Rajnish Kumar Pathela
            </a>
          </Title>
        </div>
      </Content>
    );
  }
}

export default Contact;
