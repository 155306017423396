import React, {Component} from 'react'
import { Layout, Typography, Image, Row, Col, Divider, Spin } from 'antd';
const { Content } = Layout;
const { Title } = Typography

class About extends Component {

    render() {
        return (
          <Content
            style={{ padding: "5px 50px", marginTop: 64, textAlign: "left" }}
          >
            <Title level={2}>Home Town</Title>
            <Title level={4}>Varanasi</Title>
            <Title level={2}>Work Experience</Title>
            <Title level={4}>
              <ul>
                <li>LIC Agent: 1996 - Current</li>
                <li>New India Assurance Agent: 1996 - Current</li>
                <li>HDFC ERGO(earlier Apollo Munich) Agent: 2008 - Current</li>
                <li>Cloth Merchant: 1983 - 1996</li>
                <li>Electrical Engineering Practice: 1983 - 1992</li>
                <li>NCC: 1981 - 1985</li>
                <li>NSS Scouting and Guiding: 1978 - 1980</li>
              </ul>
            </Title>
            <Title level={2}>Education</Title>
            <Title level={4}>
              <ul>
                <li>
                  Senior Secondary: Dayalbagh Educational Institute - 1982
                </li>
                <li>
                  B.Sc. Maths Hons.: Shaheed Bhagat Singh College and Harish
                  Chandra PG College: 1982 -1985
                </li>
              </ul>
            </Title>
            <Title level={2}>Achievements</Title>
            <Title level={4}>
              <ul>
                <li>
                  LIC Chairman’s Club Member and Chief Life Insurance Advisor
                </li>
                <li>NCC 'C' certificate holder | Part of Paratroopers Camp</li>
                <li>Former NSS Member</li>
              </ul>
            </Title>
            <Divider />
            <Image.PreviewGroup>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    width="100%"
                    placeholder={<Spin />}
                    src="https://drive.google.com/uc?export=view&id=1wAgw80mVQ7XP_OaGyh3bpaLuWwY-ZmAO"
                    alt="lic_shield"
                    height={300}
                    preview={true}
                  />
                </Col>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=1p0L76H9RswN0a1eXPlyeulgoi0dtQdxK"
                    alt="lord_shiva"
                    height={300}
                  />
                </Col>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=11os3W9k1SuiflCmJQQMRz7i37u9N6Ojh"
                    alt="NCC_C"
                    height={300}
                  />
                </Col>
              </Row>
              <Divider />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=1vN3oKVaAJYzdGgyCPpcKn88Kx9-3nClr"
                    alt="traffic"
                    height={300}
                  />
                </Col>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=1nwcYxPY4OzWWHW-iI-sjA9ZqOpFLdVeQ"
                    alt="NCC_B"
                    height={300}
                  />
                </Col>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=11nh7cDUxk_OynSzzRSXh92v79hDvnOuf"
                    alt="NCC_B1"
                    height={300}
                  />
                </Col>
              </Row>
              <Divider />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=11lU7ndW-uBLJLouyAEGa4bt1XHU5n4Sx"
                    alt="NCC_C1"
                    height={300}
                  />
                </Col>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=11iBB-NIwq5tx-rMj5e0QbfnKnb-iKFAl"
                    alt="PARATROOPER_1"
                    height={300}
                  />
                </Col>
                <Col span={8}>
                  <Image
                    style={{ cursor: "pointer" }}
                    placeholder={<Spin />}
                    width="100%"
                    src="https://drive.google.com/uc?export=view&id=1H0Dib2XbpnBgWiPn_BfZY6AR_C31Brg2"
                    alt="PARATROOPER"
                    height={300}
                  />
                </Col>
              </Row>
            </Image.PreviewGroup>
          </Content>
        );

    }
}

export default About;